import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "store";
import { uploadFileBulk, UploadResponse } from "services/modules/file";
import {
  CreateDumpFormI,
  CreateDumpPayloadI,
  DumpFormI,
  DumpPayload,
  UpdateDumpFormI,
} from "_interfaces/dump.interfaces";
import { useCreateDumpsMutation, useUpdateDumpMutation } from "services/modules/dump";
import { toast } from "react-toastify";

const useUpdateDumpForm = (id?: string) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createDumps, updateState] = useUpdateDumpMutation();
  const { accessToken } = useAppSelector((state) => state.auth);

  const schema = yup.object().shape({});

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    setValue,
    reset,
  } = useForm<UpdateDumpFormI>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const create = async (data: UpdateDumpFormI) => {
    try {
      setIsLoading(true);
      let newImage = '';
      if (data.productFile.file) {
        newImage = (await uploadFileBulk(accessToken!, data.productFile.file, 0)).url;
      }
      const payload: DumpPayload = {
        title: data.title,
        productUrl: data.productFile.file ? newImage : data.productFile.url!,
        creator: parseInt(data.creator),
        operatorType: data.operatorType,
        fileType: data.fileType,
        imageType: data.imageType,
        style: data.style,
        layer: data.layer,
        theme: parseInt(data.theme),
      };
      await createDumps({ body: payload, id: id! }).unwrap();
      toast.success("Update success!");
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = handleSubmit(create);

  return {
    handleUpdate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    updateState,
    watch,
    setValue,
    reset,
  };
};

export default useUpdateDumpForm;

