import CInput from "components/input";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-daisyui";
import { IoClose } from "react-icons/io5";

const MetadataModal = ({
  open,
  handleClose,
  description,
  title,
}: {
  open: boolean;
  handleClose: (data?: { description: string; title: string }) => void;
  description: string;
  title: string;
}) => {
  const [_title, setTitle] = useState("");
  const [_description, setDescription] = useState("");

  useEffect(() => {
    setTitle(title);
  }, [title]);

  useEffect(() => {
    setDescription(description);
  }, [description]);

  const onClose = () => {
    setTitle(title);
    setDescription(description);
    handleClose();
  };

  const onSave = () => {
    handleClose({ title: _title, description: _description });
  };

  return (
    <Modal
      className="bg-white w-1/3 max-w-[900px]"
      open={open}
    >
      <Modal.Header className="flex flex-row justify-between">
        Update metadata
        <IoClose onClick={onClose} />
      </Modal.Header>
      <Modal.Body className="overflow-scroll p-2 flex flex-col gap-4">
        <div>
          <label className="font-semibold text-[#262626]">Title</label>
          <CInput
            value={_title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-[#262626]">Description</label>
          <textarea
            className="border border-[#BDBDBD] rounded-lg w-full"
            value={_description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
          <Button
            type="reset"
            className="border-seeds text-seeds"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onSave}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MetadataModal;
