import moment from "moment";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import "react-toastify/dist/ReactToastify.css";
import {
  protectedRoutesAdmin,
  protectedRoutesCollaborator,
  publicRoutes,
} from "./routes";
import { useNavigate, useRoutes } from "react-router-dom";
import { deleteTokenAuth } from "store/auth";
import { ManagementType } from "_interfaces/collaborator.interfaces";

const AppRoutes = () => {
  const { accessToken, user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loggingOut = () => {
    dispatch(deleteTokenAuth());
  };

  useEffect(() => {
    if (!accessToken) {
      loggingOut();
      navigate("/");
    }
  }, [accessToken]);

  const protectedRoutes =
    user?.role === ManagementType.Admin
      ? protectedRoutesAdmin
      : protectedRoutesCollaborator;
  const permittedRoutes = accessToken ? protectedRoutes : publicRoutes;
  const element = useRoutes(permittedRoutes);

  return <div>{element}</div>;
};

export default AppRoutes;
