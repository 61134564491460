import { ChatI, ChatPayload, ReactionPayload } from "_interfaces/chat.interfaces";
import { useEffect, useState } from "react";
import { uploadFile } from "services/modules/file";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "store";

const useChat = () => {
  const [messages, setMessages] = useState<ChatI[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const { accessToken } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const newSocket = io("https://api.drizycraft.com/chat", {
      path: "/auth/socket.io",
      transports: ["websocket"],
    });

    newSocket.on("connect", () => {
      console.log("Connected to chat server");
    });

    newSocket.on("loadMessages", (loadedMessages: ChatI[]) => {
      setMessages(loadedMessages.reverse());
    });

    newSocket.on("message", (message: ChatI) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    newSocket.on("reaction", (message: ChatI) => {
      setMessages((prevMessages) => {
        const newMessage = [...prevMessages].map(item => {
          if (item.id === message.id) {
            return message;
          } else {
            return item;
          }
        });
        return newMessage;
      });
    });

    newSocket.on("delete", (id: string) => {
      setMessages((prev) => [...prev].filter(item => item.id !== id));
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const sendMessage = async (content: ChatPayload, file?: File) => {
    if (socket) {
      if (file) {
        content.attachment = await uploadFile(accessToken!, file);
      }
      socket.emit("message", content);
    }
  };

  const sendReaction = async (content: ReactionPayload) => {
    if (socket) {
      socket.emit("reaction", content);
    }
  };

  const deleteMessage = async (content: string) => {
    if (socket) {
      socket.emit("delete", content);
    }
  };

  return { messages, sendMessage, sendReaction, deleteMessage };
};

export default useChat;

