import { GetAffiliateResI } from "_interfaces/affiliate.interfaces";
import { CampaignUserRes } from "_interfaces/campaign-user.interfaces";
import { GetMessageHistoryI } from "_interfaces/messages.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import {
  GetDownloadResI,
  GetUsersResI,
  User,
} from "_interfaces/user.interfaces";
import { Api } from "services/api";

export const userApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<GetUsersResI, PagingDTO>({
      query: (params) => ({ url: "auth/admin/user", params }),
      keepUnusedDataFor: 0,
    }),
    getCampaignUsers: build.query<CampaignUserRes, PagingDTO>({
      query: (params) => ({ url: "auth/admin/campaign-users", params }),
      keepUnusedDataFor: 0,
    }),
    getCampaignMessages: build.query<GetMessageHistoryI, PagingDTO>({
      query: (params) => ({ url: "auth/campaign/campaign-messages", params }),
      keepUnusedDataFor: 0,
    }),
    getUserById: build.query<User, string>({
      query: (id) => ({ url: `auth/admin/user/${id}` }),
      keepUnusedDataFor: 0,
    }),
    block: build.mutation<void, string>({
      query: (id) => ({
        url: `auth/admin/block-user/${id}`,
        method: "POST",
      }),
    }),
    unblock: build.mutation<void, string>({
      query: (id) => ({
        url: `auth/admin/unblock-user/${id}`,
        method: "POST",
      }),
    }),
    // user history
    getSubcription: build.query<
      {
        data: {
          product: string;
          start_date: string;
          end_date: string;
          payment: string;
        };
      },
      string
    >({
      query: (userId) => `billing/admin/user-sub/${userId}`,
      keepUnusedDataFor: 0,
    }),
    getDownloads: build.query<
      GetDownloadResI,
      { id: string; params: PagingDTO }
    >({
      query: ({ id, params }) => ({
        url: `billing/admin/user-downloads/${id}`,
        params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useBlockMutation,
  useUnblockMutation,
  useGetUserByIdQuery,
  useGetSubcriptionQuery,
  useGetDownloadsQuery,
  useGetCampaignUsersQuery,
  useGetCampaignMessagesQuery,
} = userApi;
