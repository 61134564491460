import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { userDetailRouteName } from "./detail.page";
import { Button } from "react-daisyui";
import { MdLock, MdLockOpen } from "react-icons/md";
import { useEffect, useState } from "react";
import ConfirmationModal from "components/confirmation-modal";
import Pagination from "components/table/pagination";
import {
  useBlockMutation,
  useGetUsersQuery,
  useUnblockMutation,
} from "services/modules/user";
import { PagingDTO } from "_interfaces/pagination.interface";
import { User } from "_interfaces/user.interfaces";

export const userManagementRouteName = "";
const UserPage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, refetch } = useGetUsersQuery(params);
  const [block, blockState] = useBlockMutation();
  const [unblock, unblockState] = useUnblockMutation();
  const [isBlockPopupOpen, setIsBlockPopupOpen] = useState<{
    open: boolean;
    action?: "block" | "unblock";
    id?: string;
  }>({ open: false });

  useEffect(() => {
    if (blockState.isSuccess) {
      setIsBlockPopupOpen({ open: false });
      refetch();
    }
  }, [blockState.isSuccess, unblockState.isSuccess]);

  const handleBlockUnblock = () => {
    if (isBlockPopupOpen.action === "block") {
      block(isBlockPopupOpen.id!);
    } else {
      unblock(isBlockPopupOpen.id!);
    }
  };

  const header: Columns<User>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "displayName",
      label: "Display Name",
      render: (data) => (
        <div
          onClick={() => {
            navigate(userDetailRouteName.replace(":id", data.id!));
          }}
          className="underline hover:text-blue-800 cursor-pointer"
        >
          {data.displayName}
        </div>
      ),
    },
    {
      fieldId: "email",
      label: "Email",
    },
    {
      fieldId: "createdAt",
      label: "Registered At",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
    {
      fieldId: "id",
      label: "Membership",
      render: (data) => {
        return (
          <div className="capitalize">
            {/* {data.membership ? data.membership.type : "Non-membership"} */}
          </div>
        );
      },
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row gap-2">
          {!data.blocked ? (
            <Button
              fullWidth={true}
              variant="outline"
              color="error"
              startIcon={<MdLock />}
              onClick={() => {
                setIsBlockPopupOpen({
                  open: true,
                  action: "block",
                  id: data.id,
                });
              }}
            >
              Block
            </Button>
          ) : (
            <Button
              fullWidth={true}
              variant="outline"
              color="warning"
              startIcon={<MdLockOpen />}
              onClick={() => {
                setIsBlockPopupOpen({
                  open: true,
                  action: "unblock",
                  id: data.id,
                });
              }}
            >
              Unblock
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          User Management
        </h3>
        <SearchInput
          placeholder="Search"
          onSubmit={({ text }) => {
            setParams({ page: 1, limit: 10, search: text });
          }}
        />
      </div>
      <Table<User>
        data={data?.data}
        columns={header}
        loading={isLoading}
      />
      <Pagination
        currentPage={params.page}
        totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
        onPageChange={(page) => {
          setParams((prev) => ({ ...prev, page }));
        }}
      />
      <ConfirmationModal
        isOpen={isBlockPopupOpen.open}
        alertType="warning"
        title={
          isBlockPopupOpen.action === "block"
            ? "Block Account"
            : "Unblock Account"
        }
        subTitle={`Are you sure want to continue ${
          isBlockPopupOpen.action === "block" ? "block" : "unblock"
        } this account?`}
        onClose={() => setIsBlockPopupOpen({ open: false })}
        onConfirm={handleBlockUnblock}
      />
    </ContentContainer>
  );
};

export default UserPage;
