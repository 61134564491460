import { Meta } from "./pagination.interface";

export enum CreatorType {
  Original = "Original",
  Collaborator = "Collaborator",
  Fans = "Fans",
}

export enum OperationType {
  Cut = "Cut",
  Draw = "Draw",
  Print = "Print",
  CutnDraw = "CutnDraw",
  Engrave = "Engrave",
}

export enum FileType {
  SVG = "SVG",
  PNG = "PNG",
  JPG = "JPG",
}

export enum StyleType {
  Monogram = "Monogram",
  Colorful = "Colorful",
}

export enum LayerType {
  Single = "Single",
  Multi = "Multi",
}

export enum ThemeType {
  Mature = "Mature",
  Nudity = "Nudity",
  Suggestive = "Suggestive",
  DragUse = "DragUse",
  AlcoholTobaco = "AlcoholTobaco",
  Disturbing = "Disturbing",
  MatureHumor = "MatureHumor",
}

export enum ImageType {
  Shape = "Shape",
  Element = "Element",
  Text = "Text",
  Monogram = "Monogram",
  Phrases = "Phrases",
  Illustration = "Illustration",
  Templates = "Templates",
}

export interface DumpI {
  id: string;
  title: string;
  productUrl: string;
  productCode: string;
  creator: CreatorType;
  newCreatorId: number;
  operationType: OperationType;
  fileType: FileType;
  imageType: ImageType;
  style: StyleType;
  layer: LayerType;
  theme: ThemeType;
  favoriteCount: number;
  authorId: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  downloads: any[];
}

export interface GetDumpsResI {
  data: DumpI[];
  meta: Meta;
}

export interface DumpPayload {
  title: string;
  productUrl: string;
  creator: number;
  operatorType: OperationType;
  fileType: FileType;
  imageType: ImageType;
  style: StyleType;
  layer: LayerType;
  theme: number;
}

export interface DumpFormI {
  title: string;
  productFile: File;
  creator: string;
  operatorType: OperationType;
  fileType: FileType;
  imageType: ImageType;
  style: StyleType;
  layer: LayerType;
  theme: string;
}

export interface UpdateDumpFormI {
  title: string;
  productFile: { file?: File; url?: string };
  creator: string;
  operatorType: OperationType;
  fileType: FileType;
  imageType: ImageType;
  style: StyleType;
  layer: LayerType;
  theme: string;
}

export interface CreateDumpPayloadI {
  testAja: DumpPayload[];
}

export interface CreateDumpFormI {
  data: DumpFormI[];
}

export interface GetThemeResI {
  data: ThemeI[];
}

export interface ThemeI {
  id: string;
  name: string;
  createdAt: Date;
  deletedAt?: Date;
}

