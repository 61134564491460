import ContentContainer from "components/container";
import {
  FaDollarSign,
  FaDownload,
  FaFileInvoiceDollar,
  FaUser,
  FaUserFriends,
} from "react-icons/fa";

export const dashboardRouteName = "dashboard";
const DashboardPage = () => {
  return (
    <>
      <h3 className="text-2xl text-[#262626] font-semibold">Dashboard</h3>
      <div className="grid grid-cols-3 gap-6 mt-6">
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDollarSign
              className="text-blue-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">Orders</div>
              <div className="text-lg font-bold text-blue-600">
                $1,000,000{" "}
                <div className="inline-flex text-sm text-gray-600">(April)</div>
              </div>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDollarSign
              className="text-green-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">
                Success Orders
              </div>
              <div className="text-lg font-bold text-green-600">
                $999,900{" "}
                <div className="inline-flex text-sm text-gray-600">(April)</div>
              </div>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDollarSign
              className="text-red-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">
                Failed Orders
              </div>
              <div className="text-lg font-bold text-red-600">
                $100{" "}
                <div className="inline-flex text-sm text-gray-600">(April)</div>
              </div>
            </div>
          </div>
        </ContentContainer>
      </div>
      <div className="grid grid-cols-3 grid-flow-row-dense items-start gap-6 mt-6">
        <div className="flex flex-col gap-6">
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaUserFriends
                className="text-green-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  Total Users
                </div>
                <div className="text-lg font-bold text-green-600">500,000</div>
              </div>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaUserFriends
                className="text-yellow-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  New Users
                </div>
                <div className="text-lg font-bold text-yellow-600">
                  500{" "}
                  <div className="inline-flex text-sm text-gray-600">
                    (April)
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDownload
              className="text-blue-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">Downloads</div>
              <div className="text-lg font-bold text-blue-600">1,000,000</div>
            </div>
          </div>
        </ContentContainer>
        <div className="flex flex-col gap-6">
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaFileInvoiceDollar
                className="text-green-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  Active Membership
                </div>
                <div className="text-lg font-bold text-green-600">300,000</div>
              </div>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaFileInvoiceDollar
                className="text-yellow-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  New Membership
                </div>
                <div className="text-lg font-bold text-yellow-600">
                  700{" "}
                  <div className="inline-flex text-sm text-gray-600">
                    (April)
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
