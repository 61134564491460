import {
  CreateArticlePayload,
  GetArticleByIdRes,
  GetArticlesQuery,
  GetArticlesResI,
} from "_interfaces/article.interfaces";
import { Api } from "services/api";

export const articleApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getArticles: build.query<GetArticlesResI, GetArticlesQuery>({
      query: (params) => {
        return {
          url: "/crafter/admin/article",
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getArticleById: build.query<GetArticleByIdRes, string>({
      query: (id) => `/crafter/admin/article/by-id/${id}`,
      keepUnusedDataFor: 0,
    }),
    createArticle: build.mutation<void, CreateArticlePayload>({
      query(body) {
        return {
          url: `/crafter/admin/article`,
          method: "POST",
          body,
        };
      },
    }),
    updateArticle: build.mutation<
      void,
      { id: string; body: CreateArticlePayload }
    >({
      query({ body, id }) {
        return {
          url: `/crafter/admin/article/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    deleteArticle: build.mutation<void, string>({
      query(id) {
        return {
          url: `/crafter/admin/article/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetArticlesQuery,
  useLazyGetArticleByIdQuery,
  useCreateArticleMutation,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
} = articleApi;
