import ContentContainer from "components/container";
import moment from "moment";
import NotFoundPage from "pages/not-found/index.page";
import { Badge } from "react-daisyui";
import { useParams } from "react-router-dom";
import PurchaseHistorySection from "./section/purchase-history.section";
import DownloadHistorySection from "./section/download-history.section";
import {
  useGetSubcriptionQuery,
  useGetUserByIdQuery,
} from "services/modules/user";

export const userDetailRouteName = ":id";
const UserDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isError } = useGetUserByIdQuery(id!);
  const { data: subsData } = useGetSubcriptionQuery(id!);

  if (isError) return <NotFoundPage />;
  return (
    <>
      <ContentContainer>
        <div className="flex flex-row gap-2 items-center mb-4">
          <h3 className="text-2xl text-[#262626] font-semibold">User Detail</h3>
          {data?.blocked ? (
            <Badge
              size="lg"
              className="text-white"
              color="error"
            >
              Blocked
            </Badge>
          ) : (
            <Badge
              size="lg"
              className="text-white"
              color="success"
            >
              Active
            </Badge>
          )}
        </div>
        <div className="w-full grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            <label className="text-lg">Display Name</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.displayName}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">Email</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.email}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">First Name</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.username}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">Last Login At</label>
            <div className="font-semibold text-base text-gray-700">
              {moment(data?.updatedAt).format("HH:mm MMM D, YYYY")}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">Last Name</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.username}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">Membership</label>
            <div className="font-semibold text-base text-gray-700 capitalize">
              {subsData?.data
                ? `${subsData.data.product} membership active until ${moment(
                    subsData.data.end_date,
                  ).format("MMM D, YYYY")}`
                : "------"}
            </div>
          </div>
        </div>
      </ContentContainer>
      <div className="mt-4 grid grid-cols-2 gap-4">
        <PurchaseHistorySection id={id!} />
        <DownloadHistorySection id={id!} />
      </div>
    </>
  );
};

export default UserDetailPage;
