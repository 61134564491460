import {
  TransactionI,
  TransactionReqDto,
} from "_interfaces/transaction.interfaces";
import ContentContainer from "components/container";
import CInput from "components/input";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { userDetailRouteName } from "pages/user/detail.page";
import { useState } from "react";
import { Badge, Tooltip } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetTransactionsQuery } from "services/modules/transaction";

export const transactionRouteName = "";
const TransactionPage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<TransactionReqDto>({
    page: 1,
    limit: 10,
    from: moment().format("YYYY-MM-DD"),
    to: moment().add("days", 7).format("YYYY-MM-DD"),
  });
  const { data, isLoading, isFetching } = useGetTransactionsQuery(params);
  const header: Columns<TransactionI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "status",
      label: "Status",
      render: (data) => (
        <Badge
          color={data.status ? "success" : "error"}
          className="text-white capitalize"
        >
          {data.status ? "success" : "failed"}
        </Badge>
      ),
    },
    {
      fieldId: "id",
      label: "User",
      render: (data) => (
        <div
          onClick={() => {
            navigate(`/user/${data.userId}`);
          }}
          className="underline hover:text-blue-800 cursor-pointer"
        >
          {data.user.displayName}
        </div>
      ),
    },
    {
      fieldId: "id",
      label: "Item",
      render: (data) => (
        <Tooltip
          color="neutral"
          message={data.product.name}
          className="text-wrap"
        >
          {data.product.name.substring(0, 20)}...
        </Tooltip>
      ),
    },
    {
      fieldId: "price",
      label: "Price",
      render: (data) => `${data.price / 100} $`,
    },
    {
      fieldId: "checkoutId",
      label: "Checkout Id",
      render: (data) => (
        <div
          onClick={() => {
            navigator.clipboard.writeText(data.checkoutId);
            toast("Checkout ID coppied!");
          }}
          className="cursor-pointer"
        >
          <Tooltip
            color="neutral"
            message={data.checkoutId}
            className="text-wrap"
          >
            {data.checkoutId.substring(0, 10)}...
          </Tooltip>
        </div>
      ),
    },
    {
      fieldId: "createdAt",
      label: "Date",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
  ];
  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">Transactions</h3>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-1 items-center">
            <label>From</label>
            <CInput
              type="date"
              value={params.from}
              onChange={(e) =>
                setParams((prev) => ({
                  ...prev,
                  from: moment(e.target.value).format("YYYY-MM-DD"),
                }))
              }
            />
          </div>
          <div className="flex flex-row gap-1 items-center">
            <label>To</label>
            <CInput
              type="date"
              value={params.to}
              onChange={(e) =>
                setParams((prev) => ({
                  ...prev,
                  to: moment(e.target.value).format("YYYY-MM-DD"),
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <Table<TransactionI>
          data={data?.data}
          columns={header}
          loading={isLoading || isFetching}
        />
        <Pagination
          currentPage={params.page}
          totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
          onPageChange={(page) => {
            setParams((prev) => ({ ...prev, page }));
          }}
        />
      </div>
    </ContentContainer>
  );
};

export default TransactionPage;
