import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "store";
import { uploadFileBulk, UploadResponse } from "services/modules/file";
import {
  CreateDumpFormI,
  CreateDumpPayloadI,
  DumpPayload,
} from "_interfaces/dump.interfaces";
import { useCreateDumpsMutation } from "services/modules/dump";
import { toast } from "react-toastify";

const useCreateDumpForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createDumps] = useCreateDumpsMutation();
  const { accessToken } = useAppSelector((state) => state.auth);

  const schema = yup.object().shape({});

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    setValue,
    reset,
  } = useForm<CreateDumpFormI>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const create = async (data: CreateDumpFormI) => {
    try {
      setIsLoading(true);
      const promises: Promise<UploadResponse>[] = [];
      data.data.forEach((item, i) => {
        if (item.productFile) {
          promises.push(uploadFileBulk(accessToken!, item.productFile, i));
        }
      });
      const images = await Promise.all(promises);
      let temp = new Map<number, string>();
      images.forEach((item) => {
        temp.set(item.id, item.url);
      });
      const dumps: DumpPayload[] = data.data.map((item, i) => ({
        title: item.title,
        productUrl: temp.get(i)!,
        creator: parseInt(item.creator),
        operatorType: item.operatorType,
        fileType: item.fileType,
        imageType: item.imageType,
        style: item.style,
        layer: item.layer,
        theme: parseInt(item.theme),
      }));
      const payload: CreateDumpPayloadI = { testAja: dumps };
      await createDumps(payload).unwrap();
      toast.success("Upload success!");
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = handleSubmit(create);

  return {
    handleCreate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    setValue,
    reset,
  };
};

export default useCreateDumpForm;

