import React from "react";
import { useState } from "react"
import { FaTrash } from "react-icons/fa6";
import { Ellipsis } from "assets/images";

const DeleteChat = ({handleDelete}: {handleDelete: () => void}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='relative'>
      <img
        src={Ellipsis}
        className='w-4 h-4 cursor-pointer'
        onClick={() => setOpen(prev => !prev)}
      />
      <div
        className={`absolute cursor-pointer right-0 flex flex-row items-center justify-center gap-2 bg-white p-2 rounded-lg ${open ? "block" : "hidden"}`}
        onClick={() => {
          handleDelete();
          setOpen(false);
        }}
      >
        <FaTrash />
        <p className="font-katide-regular text-xs">Delete</p>
      </div>
    </div>
  )
}

export default DeleteChat;