import { CampaignUserI } from "_interfaces/campaign-user.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import { useState } from "react";
import { useGetCampaignUsersQuery } from "services/modules/user";

const CampaignUser = () => {
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetCampaignUsersQuery(params);
  const header: Columns<CampaignUserI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Display Name",
    },
    {
      fieldId: "email",
      label: "Email",
    },
    {
      fieldId: "id",
      label: "Downloads",
      render: (data) => {
        return (
          <div>{data.downloads.length}</div>
        )
      }
    },
    {
      fieldId: "id",
      label: "Favorites",
      render: (data) => {
        return (
          <div>{data.favorites.length}</div>
        )
      }
    },
  ];

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Campaign User ({data?.meta.total ?? 0})
        </h3>
        <SearchInput
          placeholder="Search"
          onSubmit={({ text }) => {
            setParams({ page: 1, limit: 10, search: text });
          }}
        />
      </div>
      <Table<CampaignUserI>
        data={data?.data}
        columns={header}
        loading={isLoading}
      />
      <Pagination
        currentPage={params.page}
        totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
        onPageChange={(page) => {
          setParams((prev) => ({ ...prev, page }));
        }}
      />
    </ContentContainer>
  )
}

export default CampaignUser;