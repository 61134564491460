import { OperationType } from "_interfaces/dump.interfaces";
import { Cut, Draw, Print, Laser } from "assets/images";

export const generateOperationImage = (operation: OperationType): string[] => {
  switch (operation) {
    case OperationType.Cut:
      return [Cut, Draw];
    case OperationType.Draw:
      return [Draw];
    case OperationType.Print:
      return [Print];
    case OperationType.CutnDraw:
      return [Cut, Draw];
    default:
      return [Laser];
  }
};