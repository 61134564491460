import { ChatI, ChatPayload, ReactionPayload } from "_interfaces/chat.interfaces";
import { Cut } from "assets/images";
import useChat from "hooks/chat/useChat";
import { useEffect, useRef, useState } from "react";
import { FaCamera, FaReply } from "react-icons/fa";
import { FiSend, FiX } from "react-icons/fi";
import { IoClose, IoSend } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { useAppSelector } from "store";
import Reaction from "./reaction.component";
import DeleteChat from "./delete.component";

const ChatSection = () => {
  const [newMessage, setNewMessage] = useState("");
  const { messages, sendMessage, sendReaction, deleteMessage } = useChat();
  const [file, setFile] = useState<File>();
  const { user } = useAppSelector((state) => state.auth);
  const endChatRef = useRef<any>();
  const [replyTo, setReplyTo] = useState<ChatI>();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const payload: ChatPayload = {
        content: newMessage.trim(),
        admin: user!.id,
      }
      if (replyTo) {
        if (replyTo.admin) {
          payload.replyToAdmin = replyTo.admin.id;
        }
        if (replyTo.user) {
          payload.replyTo = replyTo.user.id;
        }
      }
      await sendMessage(payload, file);
      setNewMessage("");
      setReplyTo(undefined);
      setFile(undefined);
    }
  };

  const generateName = (item: ChatI) => {
    if (item.admin) return item.admin.name;
    if (item.user) return item.user.name;
    return "Unknown";
  };

  const generateReplyName = (item: ChatI) => {
    if (item.replyToAdmin) return item.replyToAdmin.name;
    if (item.replyTo) return item.replyTo.name;
    return "";
  };

  const handleReaction = (item: ChatI, emoji: string) => {
    const reaction: ReactionPayload = {
      reaction: emoji,
      message: item.id,
      admin: user!.id,
    }
    sendReaction(reaction);
  }

  useEffect(() => {
    if (endChatRef) {
      endChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [endChatRef, messages]);

  return (
    <div className="z-[999] fixed top-[60px] right-[100px] bg-white w-[384px] rounded-xl shadow-md border-[#EAEAEA]">
      <div className="bg-[#008ECC] p-5 rounded-t-xl">
        <h1 className="text-white text-4xl">Brizy Chat Room</h1>
      </div>
      {user ?
        <div className="flex flex-col gap-5 p-2 lg:p-3 xl:p-4 2xl:p-5 max-h-[450px] lg:max-h-[250px] 2xl:max-h-[450px] overflow-x-scroll">
          {messages.map((item) => (
            <div key={item.id} className="flex flex-col gap-1.5">
              <div className="flex flex-row gap-2.5 items-center">
                {item.admin ?
                  <div className='relative'>
                    <div className="min-w-7 min-h-7 rounded-full bg-blue-300" />
                    <MdVerified className='absolute top-0 -right-1 w-3 h-3 text-[#1A214C]' />
                  </div>
                  :
                  item.user?.avatar ?
                    <img src={item.user.avatar} className='min-w-7 min-h-7 !w-7 !h-7 rounded-full' />
                    :
                    <div className="min-w-7 min-h-7 rounded-full bg-blue-300" />
                }
                <p className="2xl:text-sm text-xs font-normal flex flex-row flex-wrap gap-1.5">
                  {item.replyTo || item.replyToAdmin
                    ?
                    <FaReply className="text-[#1A214C]" />
                    :
                    null
                  }
                  <span>
                    {generateName(item)}
                  </span>
                  {item.replyTo || item.replyToAdmin
                    ?
                    <span className='text-[#777777]'>replied to</span>
                    :
                    null
                  }
                  <span>
                    {generateReplyName(item)}
                  </span>
                </p>
              </div>
              {item.attachment && (
                <div className="bg-[#EAEAEA] rounded-xl rounded-tl-none p-2 flex flex-col gap-3.5 w-fit">
                  <img src={item.attachment} className="max-h-[200px] object-contain" />
                </div>
              )}
              <div className="bg-[#EAEAEA] rounded-xl rounded-tl-none p-2 flex flex-col gap-3.5 relative">
                <p className="text-[#777777] text-sm text-wrap break-all">{item.content}</p>
                <div className='self-end flex flex-row gap-1'>
                  {item.admin?.id === user.id
                    ?
                    null
                    :
                    <FaReply className="text-[#777777] cursor-pointer" onClick={() => setReplyTo(item)} />
                  }
                  {item.reactions.map(r => r.userId).includes(user.id)
                    ?
                    null
                    :
                    <Reaction onReaction={(emoji) => handleReaction(item, emoji.imageUrl)} />
                  }
                  {item.admin?.id === user.id
                    ?
                    <DeleteChat
                      handleDelete={() => {
                        deleteMessage(item.id);
                      }}
                    />
                    :
                    null
                  }
                </div>
                {item.reactions.length > 0
                  ?
                  <div className='absolute left-0 -bottom-1 text-xs h-5 rounded-full flex items-center justify-center'>
                    {item.reactions.slice(0, 3).map((reaction, index) => (
                      <img key={index} src={reaction.reaction} className='-ml-2 w-5 h-5 min-w-5 min-h-5' />
                    ))}
                    {item.reactions.length > 3 &&
                      <div className='w-5 h-5 rounded-full bg-white flex items-center justify-center -ml-2'>
                        {`${item.reactions.length - 3}+`}
                      </div>
                    }
                  </div>
                  :
                  null
                }
              </div>
            </div>
          ))}
          <div ref={endChatRef} />
        </div>
        :
        <div className="py-10 text-center">Chat unavailable</div>
      }
      <form
        onSubmit={handleSubmit}
        className="border-[#EAEAEA] border-t rounded-b-xl px-2.5 py-[15px] flex flex-col gap-3"
      >
        {replyTo &&
          <div className='text-xs flex flex-row gap-1 w-full'>
            <div className='text-[#777777]'>
              Replying to
            </div>
            <div>
              {generateName(replyTo)}
            </div>
            <FiX onClick={() => setReplyTo(undefined)} className='flex-grow cursor-pointer' />
          </div>
        }
        {file && (
          <div className="flex relative h-16 w-16">
            <img
              src={URL.createObjectURL(file)}
              className="h-16 w-16 object-contain bg-blue-gray-300"
            />
            <div
              className="cursor-pointer z-10 absolute -top-2 -right-2 bg-red-300 rounded-full"
              onClick={() => setFile(undefined)}
            >
              <IoClose className="text-white" />
            </div>
          </div>
        )}
        <div className="flex flex-row gap-3">
          <textarea
            className="border-[#EAEAEA] border rounded-lg w-full p-2"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <div className="flex flex-col justify-between gap-2">
            <label
              htmlFor="camera"
              className="bg-[#CCCCCC] rounded-lg p-2 cursor-pointer"
            >
              <FaCamera className="text-[#777777] w-6 h-6" />
            </label>
            <input
              id="camera"
              className="hidden"
              type="file"
              accept=".svg,.png,.jpg"
              onChange={(e) => {
                setFile(e.target.files![0]);
              }}
            />
            <button
              type="submit"
              className={`bg-[#008ECC] rounded-lg p-2`}
            >
              <IoSend className="text-white w-6 h-6" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatSection;

