import { PagingDTO } from "_interfaces/pagination.interface";
import { DownloadI } from "_interfaces/user.interfaces";
import ContentContainer from "components/container";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import { Badge, Link, Tooltip } from "react-daisyui";
import { useGetDownloadsQuery } from "services/modules/user";

const DownloadHistorySection = ({ id }: { id: string }) => {
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetDownloadsQuery({
    id,
    params,
  });
  const header: Columns<DownloadI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "product",
      label: "Item",
      render: (data) => (
        <Tooltip
          color="neutral"
          message={data.product.name}
          className="text-wrap"
        >
          {data.product.name.substring(0, 20)}...
        </Tooltip>
      ),
    },
    {
      fieldId: "product",
      label: "Date",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
  ];

  return (
    <ContentContainer>
      <h3 className="text-xl text-[#262626] font-semibold mb-4">
        Download History
      </h3>
      <div className="w-full overflow-auto">
        <Table<DownloadI>
          data={data?.data}
          loading={isLoading}
          columns={header}
        />
        <Pagination
          currentPage={params.page}
          totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
          onPageChange={(page) => {
            setParams((prev) => ({ ...prev, page }));
          }}
        />
      </div>
    </ContentContainer>
  );
};

export default DownloadHistorySection;
