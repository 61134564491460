import CInput from "components/input";
import ValidationError from "components/validation/error";
import useCreatorForm from "hooks/creator/useCreatorForm";
import useThemeForm from "hooks/theme/useThemeForm";
import { useEffect } from "react";
import { Button, Modal } from "react-daisyui";
import { IoClose } from "react-icons/io5";

const CreateCreator = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: (refetch?: boolean) => void;
}) => {
  const { handleCreate, errors, register, setValue, stateCreate, watch } =
    useCreatorForm();

  useEffect(() => {
    if (stateCreate.isSuccess) {
      handleClose(true);
    }
  }, [stateCreate.isSuccess]);

  return (
    <Modal
      className="bg-white w-1/3 max-w-[900px]"
      open={open}
    >
      <Modal.Header className="flex flex-row justify-between">
        Create New Creator
        <IoClose onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body className="overflow-scroll">
        <form
          className="p-2 flex flex-col gap-4"
          onSubmit={handleCreate}
        >
          <div>
            <label
              htmlFor="merchantID"
              className="font-semibold text-[#262626]"
            >
              Name
            </label>
            <CInput
              {...register("name")}
              type="text"
            />
            <ValidationError error={errors.name} />
          </div>
          <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
            <Button
              type="reset"
              className="border-seeds text-seeds"
              onClick={() => handleClose()}
              loading={stateCreate.isLoading}
              disabled={stateCreate.isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan/90"
              loading={stateCreate.isLoading}
              disabled={stateCreate.isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCreator;

