import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useCreateThemeMutation } from "services/modules/dump";

const useThemeForm = () => {
  const [createTheme, stateCreate] = useCreateThemeMutation();
  const schema = yup
    .object({
      name: yup.string().required(),
    })
    .required();
  const defaultValues: { name: string } = {
    name: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
    setValue,
  } = useForm<{ name: string }>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const create = async (data: { name: string }) => {
    try {
      createTheme({
        name: data.name,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCreate = handleSubmit(create);

  return {
    handleCreate,
    register,
    errors,
    watch,
    defaultValues,
    control,
    setValue,
    stateCreate,
  };
};

export default useThemeForm;

