import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { CollaboratorPayloadI } from "_interfaces/collaborator.interfaces";
import { useCreateCollaboratorMutation } from "services/modules/collabolator";

const useCollaboratorForm = () => {
  const [createCollaborator, stateCreate] = useCreateCollaboratorMutation();
  const schema = yup
    .object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      password: yup.string().required(),
    })
    .required();
  const defaultValues: CollaboratorPayloadI = {
    name: "",
    email: "",
    password: ""
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    control,
  } = useForm<CollaboratorPayloadI>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const create = async (data: CollaboratorPayloadI) => {
    try {
      createCollaborator(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCreate = handleSubmit(create);

  return {
    handleCreate,
    register,
    errors,
    defaultValues,
    control,
    stateCreate,
    reset: () => reset(defaultValues),
  };
};

export default useCollaboratorForm;
