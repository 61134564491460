import {
  CategoriesResI,
  CategoryPayloadI,
} from "_interfaces/category.interfaces";
import {
  GetProductByIdRes,
  GetProductQuery,
  GetProductResI,
  ProductPayloadI,
} from "_interfaces/post.interface";
import { Api } from "services/api";

export const crafterApi = Api.injectEndpoints({
  endpoints: (build) => ({
    createCategory: build.mutation<void, CategoryPayloadI>({
      query(body) {
        let endpoint = "category";
        if (body.categoryId) endpoint = "sub-category";
        if (body.subCategoryId) endpoint = "child-category";
        return {
          url: `/crafter/admin/product/${endpoint}`,
          method: "POST",
          body,
        };
      },
    }),
    getCategories: build.query<CategoriesResI, undefined>({
      query: () => "/crafter/product/category",
      keepUnusedDataFor: 0,
    }),
    getSubCategories: build.query<CategoriesResI, undefined>({
      query: () => "/crafter/product/sub-category",
      keepUnusedDataFor: 0,
    }),
    getChildCategories: build.query<CategoriesResI, undefined>({
      query: () => "/crafter/product/child-category",
      keepUnusedDataFor: 0,
    }),
    getProduct: build.query<GetProductResI, GetProductQuery>({
      query: (params) => {
        return {
          url: "/crafter/admin/product",
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getProductById: build.query<GetProductByIdRes, string>({
      query: (id) => `/crafter/admin/product/by-id/${id}`,
      keepUnusedDataFor: 0,
    }),
    createProduct: build.mutation<void, ProductPayloadI>({
      query(body) {
        return {
          url: `/crafter/admin/product`,
          method: "POST",
          body,
        };
      },
    }),
    updateProduct: build.mutation<void, { id: string; body: ProductPayloadI }>({
      query({ body, id }) {
        return {
          url: `/crafter/admin/product/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  useGetChildCategoriesQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useLazyGetProductByIdQuery,
} = crafterApi;
