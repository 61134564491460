import { CategoryI } from "_interfaces/category.interfaces";
import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import { Button } from "react-daisyui";
import CreateCategory from "./modal/create.modal";
import { useEffect, useState } from "react";
import {
  useGetCategoriesQuery,
  useGetChildCategoriesQuery,
  useGetSubCategoriesQuery,
} from "services/modules/crafter";
import { MdDelete } from "react-icons/md";
import { ThemeI } from "_interfaces/dump.interfaces";
import { useDeleteThemeMutation, useGetThemeQuery } from "services/modules/dump";
import CreateTheme from "./modal/create.modal";
import DeletePopUp from "components/modal/other/Delete";
import { errorHandler } from "services/errorHandler";

export const themeManagementRouteName = "";
const ThemePage = () => {
  const {
    data,
    isLoading,
    refetch,
  } = useGetThemeQuery(undefined);
  const [deleteTheme] = useDeleteThemeMutation();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });
  const header: Columns<ThemeI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Theme Name",
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="error"
            startIcon={<MdDelete />}
            onClick={() => handleDeletePopUp(data.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleDeletePopUp = (id?: string) => {
    setIsDeletePopupOpen((prev) => ({ open: !prev.open, id }));
  };

  const handleDeleteArticle = async (): Promise<void> => {
    try {
      await deleteTheme(isDeletePopupOpen.id!).unwrap();
      handleDeletePopUp();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Theme Management
        </h3>
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => setShowCreate(true)}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Create Theme
          </Button>
        </div>
      </div>
      <Table
        data={data?.data}
        columns={header}
        loading={isLoading}
      />
      <CreateTheme
        open={showCreate}
        handleClose={(needRefetch) => {
          setShowCreate(false);
          if (needRefetch) {
            refetch();
          }
        }}
      />
      <DeletePopUp
        isOpen={isDeletePopupOpen.open}
        data={"Theme"}
        onClose={handleDeletePopUp}
        onEdit={async () => {
          handleDeleteArticle();
        }}
        menu="Theme"
      />
    </ContentContainer>
  );
};

export default ThemePage;
