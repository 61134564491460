import { ArticleI, GetArticlesQuery } from "_interfaces/article.interfaces";
import ContentContainer from "components/container";
import DeletePopUp from "components/modal/other/Delete";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import { Button } from "react-daisyui";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import {
  useDeleteArticleMutation,
  useGetArticlesQuery,
} from "services/modules/article";

export const articleManagementRouteName = "";
const ArticlePage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<GetArticlesQuery>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, refetch } = useGetArticlesQuery(params);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });
  const [deleteArticle] = useDeleteArticleMutation();

  const header: Columns<ArticleI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "title",
      label: "Title",
    },
    {
      fieldId: "tags",
      label: "Tags",
      render: (data) => {
        return data.tags[0] ?? "-";
      },
    },
    {
      fieldId: "createdAt",
      label: "Posted At",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
    {
      fieldId: "id",
      label: "Author",
      render: (data) => (data.author ? data.author.name : "Admin"),
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) =>
        data.deletedAt ? (
          "Deleted"
        ) : (
          <div className="flex flex-row justify-center gap-2">
            <Button
              variant="outline"
              color="info"
              startIcon={<MdEdit />}
              onClick={() => navigate(`${data.id}`)}
            >
              Edit
            </Button>
            <Button
              variant="outline"
              color="error"
              startIcon={<MdDelete />}
              onClick={() => handleDeletePopUp(data.id)}
            >
              Delete
            </Button>
          </div>
        ),
    },
  ];

  const handleDeletePopUp = (id?: string) => {
    setIsDeletePopupOpen((prev) => ({ open: !prev.open, id }));
  };

  const handleDeleteArticle = async (): Promise<void> => {
    try {
      await deleteArticle(isDeletePopupOpen.id!).unwrap();
      handleDeletePopUp();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Article Management
        </h3>
        <div className="flex flex-row gap-2">
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setParams((prev) => ({ ...prev, search: text }));
            }}
          />
          <Button
            onClick={() => {
              navigate("/article/create");
            }}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Create Article
          </Button>
        </div>
      </div>
      <Table<ArticleI>
        data={data?.data}
        columns={header}
        loading={isLoading}
      />
      <DeletePopUp
        isOpen={isDeletePopupOpen.open}
        data={"Article"}
        onClose={handleDeletePopUp}
        onEdit={async () => {
          handleDeleteArticle();
        }}
        menu="Article"
      />
    </ContentContainer>
  );
};

export default ArticlePage;
