import {
  CollaboratorPayloadI,
  GetCollaboratorResI,
} from "_interfaces/collaborator.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import { Api } from "services/api";

export const collaboratorApi = Api.injectEndpoints({
  endpoints: (build) => ({
    createCollaborator: build.mutation<void, CollaboratorPayloadI>({
      query(body) {
        return {
          url: `/auth/admin/collaborator`,
          method: "POST",
          body,
        };
      },
    }),
    getCollaborator: build.query<GetCollaboratorResI, PagingDTO>({
      query: (params) => ({ url: "/auth/admin/collaborator", params }),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCollaboratorQuery, useCreateCollaboratorMutation } =
  collaboratorApi;
