import {
  CreatorType,
  FileType,
  ImageType,
  LayerType,
  OperationType,
  StyleType,
  ThemeType,
} from "_interfaces/dump.interfaces";

export const creatorTypes = [
  { value: CreatorType.Original, label: "Original" },
  { value: CreatorType.Collaborator, label: "Collaborator" },
  { value: CreatorType.Fans, label: "Fans" },
];

export const operationTypes = [
  { value: OperationType.Cut, label: "Cut" },
  { value: OperationType.Draw, label: "Draw" },
  { value: OperationType.Print, label: "Print" },
  { value: OperationType.CutnDraw, label: "Cut & Draw" },
  { value: OperationType.Engrave, label: "Engrave" },
];

export const fileTypes = [
  { value: FileType.SVG, label: "SVG" },
  { value: FileType.PNG, label: "PNG" },
  { value: FileType.JPG, label: "JPG" },
];

export const styleTypes = [
  { value: StyleType.Monogram, label: "Monogram" },
  { value: StyleType.Colorful, label: "Colorful" },
];

export const layerTypes = [
  { value: LayerType.Single, label: "Single" },
  { value: LayerType.Multi, label: "Multi" },
];

export const themeTypes = [
  { value: ThemeType.Mature, label: "Mature" },
  { value: ThemeType.Nudity, label: "Nudity" },
  { value: ThemeType.Suggestive, label: "Suggestive" },
  { value: ThemeType.DragUse, label: "Drag Use" },
  { value: ThemeType.AlcoholTobaco, label: "Alcohol & Tobaco" },
  { value: ThemeType.Disturbing, label: "Disturbing" },
  { value: ThemeType.MatureHumor, label: "Mature Humor" },
];

export const imageTypes = [
  { value: ImageType.Shape, label: "Shape" },
  { value: ImageType.Element, label: "Element" },
  { value: ImageType.Text, label: "Text" },
  { value: ImageType.Monogram, label: "Monogram" },
  { value: ImageType.Phrases, label: "Phrases" },
  { value: ImageType.Illustration, label: "Illustration" },
  { value: ImageType.Templates, label: "Templates" },
];

