import { AffiliateI } from "_interfaces/affiliate.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import { Button } from "react-daisyui";
import { MdCheck, MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import {
  useApproveMutation,
  useGetAffiliateRequestQuery,
  useRejectMutation,
} from "services/modules/affiliate";

export const affiliateManagementRouteName = "affiliate";
const AffiliatePage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, refetch } = useGetAffiliateRequestQuery(params);
  const [approve, approveState] = useApproveMutation();
  const [reject, rejectState] = useRejectMutation();
  const header: Columns<AffiliateI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "id",
      label: "User Name",
      render: (item) => (
        <div
          className="underline text-blue-500 cursor-pointer"
          onClick={() => navigate(`/user/${item.userId}`)}
        >
          {item.user.displayName}
        </div>
      ),
    },
    {
      fieldId: "id",
      label: "User Email",
      render: (item) => item.user.email,
    },
    {
      fieldId: "status",
      label: "Status",
      render: (item) => item.status.toUpperCase(),
    },
    {
      fieldId: "createdAt",
      label: "Requested At",
      render: (item) => moment(item.createdAt).format("MMM D, YYYY"),
    },
    {
      fieldId: "id",
      label: "Action",
      render: (item) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="success"
            startIcon={<MdCheck />}
            onClick={() => handleApprove(`${item.id}`)}
            disabled={item.status === "accepted" || item.status === "rejected"}
            loading={approveState.isLoading || rejectState.isLoading}
          >
            Accept
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdClose />}
            onClick={() => handleReject(`${item.id}`)}
            disabled={item.status === "accepted" || item.status === "rejected"}
            loading={approveState.isLoading || rejectState.isLoading}
          >
            Reject
          </Button>
        </div>
      ),
    },
  ];

  const handleApprove = async (id: string) => {
    try {
      await approve(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleReject = async (id: string) => {
    try {
      await reject(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Affiliate Requests
        </h3>
        <SearchInput
          placeholder="Search"
          onSubmit={({ text }) => {
            setParams((prev) => ({ ...prev, search: text }));
          }}
        />
      </div>
      <Table<AffiliateI>
        data={data?.data}
        columns={header}
        loading={isLoading}
        currentPage={params.page}
        limit={params.limit}
      />
      <div className="mt-6">
        <Pagination
          currentPage={data?.meta.page ?? 0}
          totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
          onPageChange={(next) =>
            setParams((prev) => ({ ...prev, page: next }))
          }
        />
      </div>
    </ContentContainer>
  );
};

export default AffiliatePage;
