import React from "react";
import { FileInput, Modal } from "react-daisyui";

export default function ImageInput({
  register,
  isWide,
  imagePreview,
  first,
  wideImage
}: {
  register: any;
  isWide?: boolean;
  wideImage?: boolean;
  imagePreview: string | undefined;
  first: boolean;
}): React.ReactElement {
  return (
    <div
      className={`w-full border-[#BDBDBD] border rounded-lg flex flex-col text-center items-center justify-center p-10 gap-3 ${
        isWide ? "col-span-2" : "col-span-1"
      }`}
    >
      {imagePreview ? (
        <div className="flex flex-col items-center gap-2">
          <img
            className={`flex mx-auto ${wideImage ? "w-full h-[250px]" : "w-[300] h-[200px]"} object-contain`}
            src={imagePreview}
            alt=""
          />
          {first && (
            <p className="text-xs text-gray-500">{`(Product Image)`}</p>
          )}
        </div>
      ) : (
        <div className="text-san-juan">
          Choose your {first ? `product image` : `image`} here
        </div>
      )}
      <FileInput
        {...register}
        size="sm"
        accept="image/*"
      />
    </div>
  );
}
