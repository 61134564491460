import { Emoji } from "assets/images";
import EmojiPicker, { type EmojiClickData } from "emoji-picker-react";
import { useState } from "react";


const Reaction = ({ onReaction }: {
  onReaction: (emoji: EmojiClickData) => void;
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative group">
      <img
        src={Emoji}
        className="cursor-pointer h-4 w-4"
        onClick={() => {
          setShow((prev) => !prev);
        }}
      />
      <div className={`absolute right-0 ${show ? "block" : "hidden"} z-50`}>
        <EmojiPicker
          searchDisabled={true}
          reactions={['1f60d', '1f923', '1f44d', '2665-fe0f']}
          reactionsDefaultOpen={true}
          allowExpandReactions={false}
          onEmojiClick={(emoji) => {
            onReaction(emoji);
            setShow(false);
          }}
        />
      </div>
    </div>
  )
}

export default Reaction;