import { CreateArticleForm } from "_interfaces/article.interfaces";
import ContentContainer from "components/container";
import CInput from "components/input";
import CancelPopUp from "components/modal/other/Cancel";
import SavePopUp from "components/modal/other/Save";
import useArticleForm from "hooks/article/useArticleForm";
import { useEffect, useState } from "react";
import { Button, Checkbox, Input } from "react-daisyui";
import { Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import { useLazyGetArticleByIdQuery } from "services/modules/article";
import JoditEditor from "jodit-react";
import ValidationError from "components/validation/error";
import { PlusIcon } from "@heroicons/react/24/outline";
import { IoClose, IoEllipsisVertical } from "react-icons/io5";
import {
  useGetCategoriesQuery,
  useGetChildCategoriesQuery,
  useGetSubCategoriesQuery,
} from "services/modules/crafter";
import CreateCategory from "pages/category/modal/create.modal";
import MetadataModal from "pages/product/modal/metadata.modal";
import ImageInput from "components/post/ImageInput";
import useFilePreview from "hooks/shared/useFilePreview";
import { calculateSEOScore } from "_helper/seoAnalyzer";

export const createArticleRouteName = "create";
export const updateArticleRouteName = ":id";
const ArticleForm = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [tag, setTag] = useState("");
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showMetadata, setShowMetadata] = useState<boolean>(false);
  const [getArticletById] = useLazyGetArticleByIdQuery();
  const { data: category, refetch: refetchCategory } =
    useGetCategoriesQuery(undefined);
  const { data: subCategory, refetch: refetchSubCategory } =
    useGetSubCategoriesQuery(undefined);
  const { data: childCategory, refetch: refetchChildCategory } =
    useGetChildCategoriesQuery(undefined);
  const [score, setScore] = useState(0);
  const {
    register,
    errors,
    isLoading,
    watch,
    handleCreate,
    handleUpdate,
    control,
    setValue,
    reset,
  } = useArticleForm(id);
  const banner = watch("banner");
  const [bannerPreview] = useFilePreview(banner.file);
  const title = watch("title");
  const metaDescription = watch("metaDescription");
  const metaTitle = watch("metaTitle");
  const desc = watch("description");
  const {
    fields: tags,
    remove: removeTag,
    append: appendTag,
  } = useFieldArray({
    control,
    name: "tags",
  });

  const handleAnalyze = () => {
    const newScore = calculateSEOScore(
      desc,
      tags.map((item) => item.text),
      metaDescription,
    );
    setScore(newScore);
  };

  // Menentukan warna progress bar berdasarkan skor
  const getColor = (score: number) => {
    if (score < 10) return "bg-red-500";
    if (score < 20) return "bg-yellow-500";
    if (score < 40) return "bg-yellow-400";
    if (score < 60) return "bg-yellow-300";
    if (score < 80) return "bg-yellow-200";
    return "bg-green-500";
  };

  const barValues = Array(5)
    .fill(0)
    .map((_, i) => Math.min(20, Math.max(0, score - i * 20)));

  const handleCancelPopup = () => {
    setIsCancelPopupOpen(!isCancelPopupOpen);
  };

  const handleSavePopup = () => {
    setIsSavePopupOpen(!isSavePopupOpen);
  };

  const handleAddTag = () => {
    const tempTags = tag.split(",");
    tempTags.forEach((item) => {
      if (item.trim() !== "") {
        appendTag({ text: item.trim() });
      }
    });
    setTag("");
  };

  useEffect(() => {
    if (id) {
      getArticle();
    }
  }, [id]);

  useEffect(() => {
    if (desc && metaDescription && tags?.length > 0) {
      handleAnalyze();
    }
  }, [desc, metaDescription, tags]);

  const getArticle = async () => {
    try {
      const {
        data: {
          article: resArticle,
          title: titleResp,
          description: descriptionResp,
        },
      } = await getArticletById(id!).unwrap();
      const dataArticle: CreateArticleForm = {
        title: resArticle.title,
        description: resArticle.description,
        categories: resArticle.categories.filter((item) => item !== null),
        subCategories: resArticle.subCategories.filter((item) => item !== null),
        childSubCategories: resArticle.chilSubCategories.filter(
          (item) => item !== null,
        ),
        tags: resArticle.tags.map((item) => ({ text: item })),
        banner: { url: resArticle.banner },
        metaTitle: titleResp,
        metaDescription: descriptionResp,
      };
      reset(dataArticle);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <form
        onSubmit={(e) => {
          if (id) {
            handleUpdate(e);
          } else {
            handleCreate(e);
          }
        }}
        className="flex flex-col gap-6 mb-6"
      >
        <div className="flex items-center justify-between gap-4 mb-2">
          <h3 className="text-2xl text-[#262626] font-bold">{`Create Article`}</h3>
          <div className="flex items-center justify-between gap-4 ml-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                void handleCancelPopup();
              }}
              className="rounded-full px-6 py-2 border-red-500/80 text-red-500/80 hover:border-red-500  hover:text-red-500"
            >
              Cancel
            </Button>
            <CancelPopUp
              isOpen={isCancelPopupOpen}
              data={id ? "Update Article" : "Create Article"}
              onClose={handleCancelPopup}
              onEdit={() => {
                navigate(-1);
                handleCancelPopup();
              }}
              menu={id ? "Update Article" : "Create Article"}
            />
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                void handleSavePopup();
              }}
              // loading={isLoading}
              className="rounded-full px-6 py-2 border-[#3AC4A0]/80 text-[#3AC4A0]/80 hover:border-[#3AC4A0]  hover:text-[#3AC4A0]"
            >
              Save
            </Button>
            <SavePopUp
              isOpen={isSavePopupOpen}
              data={id ? "Update" : "Create"}
              onClose={handleSavePopup}
              onEdit={() => {
                setIsSavePopupOpen(false);
              }}
              menu={"Article"}
            />
          </div>
        </div>
        <ImageInput
          first={false}
          isWide={true}
          imagePreview={bannerPreview ?? banner?.url}
          register={register(`banner.file`)}
          wideImage={true}
        />
        <div>
          <label
            htmlFor="merchant"
            className="font-semibold text-[#262626]"
          >
            Title
          </label>
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, value } }) => (
              <CInput
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue(
                    "metaTitle",
                    e.target.value?.replaceAll(" ", "-").toLowerCase(),
                  );
                }}
                error={errors.title}
              />
            )}
          />
        </div>
        <div>
          <label className="font-semibold">Product Description</label>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <JoditEditor
                className="without-tailwind"
                onChange={(e) => {
                  onChange(e);
                  setValue(
                    "metaDescription",
                    e.replaceAll("<br>", " ").replace(/(<([^>]+)>)/gi, ""),
                  );
                }}
                value={value}
              />
            )}
          />
          <ValidationError error={errors.description} />
        </div>
        <div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold"
            >
              Product Tags
            </label>
            <div className="w-2/3 flex flex-row gap-4">
              <Input
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                className={`w-full bg-white`}
              />
              <Button
                type="button"
                disabled={tag.trim() === ""}
                className="bg-san-juan text-white hover:bg-san-juan/90 disabled:bg-neutral-400 disabled:text-white disabled:cursor-not-allowed"
                onClick={() => handleAddTag()}
              >
                Add tag
                <PlusIcon
                  className="-mr-1 -mb-1 h-5 w-5 text-white "
                  aria-hidden="true"
                />
              </Button>
            </div>
            <div className="w-2/3 flex flex-row flex-wrap gap-2 mt-2">
              {tags.map((item, i) => (
                <div
                  key={item.id}
                  className="rounded-full bg-gray-300 flex flex-row items-center gap-1 py-1 px-3"
                >
                  <div>{item.text}</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => removeTag(i)}
                  >
                    <IoClose />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="merchant"
            className="font-semibold"
          >
            Select Categories
          </label>
          <div className="border-2 rounded-lg max-h-[200px] overflow-y-auto flex flex-col gap-2 px-4 py-2">
            <Checkbox
              color="info"
              size="sm"
              className="hidden"
              value={"hidden"}
              {...register("subCategories")}
            />
            <Checkbox
              color="info"
              size="sm"
              className="hidden"
              value={"hidden"}
              {...register("categories")}
            />
            <Checkbox
              color="info"
              size="sm"
              className="hidden"
              value={"hidden"}
              {...register("childSubCategories")}
            />
            {category?.data.map((cat, i) => (
              <>
                <div
                  key={i}
                  className="flex flex-row gap-2 items-center"
                >
                  <Checkbox
                    color="info"
                    size="sm"
                    id={`${cat.id}-${cat.name}`}
                    value={cat.name}
                    {...register("categories")}
                  />
                  <label className="text-sm">{cat.name}</label>
                </div>
                {subCategory?.data
                  .filter((sub) => sub.categoryId === cat.id)
                  .map((sub, j) => (
                    <>
                      <div
                        key={`${i}-${j}-${sub.name}`}
                        className="flex flex-row gap-2 items-center ml-6"
                      >
                        <Checkbox
                          color="info"
                          size="sm"
                          id={`${sub.id}-${sub.name}`}
                          value={sub.name}
                          {...register("subCategories")}
                        />
                        <label className="text-sm">{sub.name}</label>
                      </div>
                      {childCategory?.data
                        .filter((child) => child.subCategoryId === sub.id)
                        .map((child, k) => (
                          <div
                            key={`${j}-${k}-${child.name}`}
                            className="flex flex-row gap-2 items-center ml-12"
                          >
                            <Checkbox
                              color="info"
                              size="sm"
                              id={`${child.id}-${child.name}`}
                              value={child.name}
                              {...register("childSubCategories")}
                            />
                            <label className="text-sm">{child.name}</label>
                          </div>
                        ))}
                    </>
                  ))}
              </>
            ))}
          </div>
          <Button
            onClick={() => {
              setShowCreate(true);
            }}
            type="button"
            className="bg-san-juan text-white hover:bg-san-juan/90 disabled:bg-neutral-400 disabled:text-white disabled:cursor-not-allowed mt-2"
          >
            New Category
            <PlusIcon
              className="-mr-1 -mb-1 h-5 w-5 text-white "
              aria-hidden="true"
            />
          </Button>
        </div>
        {metaDescription && metaTitle ? (
          <>
            <div
              className="p-2 border rounded-lg shadow-lg flex flex-col gap-3 cursor-pointer"
              onClick={() => {
                setShowMetadata(true);
              }}
            >
              <label
                htmlFor="merchant"
                className="font-semibold mb-3"
              >
                Preview
              </label>
              <div className="flex flex-row gap-3">
                <div className="text-sm text-[#202124]">{`https://drizystudio.com/${metaTitle}`}</div>
                <IoEllipsisVertical className="text-gray-600" />
              </div>
              <h5 className="text-[#1a0dab] leading-3 font-normal">{`${title} - Drizy Studio`}</h5>
              <p className="text-[13px] text-[#555d66]">
                {metaDescription?.substring(0, 154)}...
              </p>
            </div>
            <div className=" w-1/2">
              <label
                htmlFor="merchant"
                className="font-semibold mb-3"
              >
                SEO Analyzer
              </label>
              <div className="flex flex-row gap-2">
                {barValues.map((value, index) => (
                  <div
                    key={index}
                    className="w-full bg-gray-200 rounded-full h-6"
                  >
                    <div
                      className={`h-6 rounded-full text-white text-center ${getColor(
                        value * 5,
                      )}`}
                      style={{ width: `${value * 5}%` }}
                    />
                  </div>
                ))}
                <label className="flex flex-row w-full">{`Score: ${score}`}</label>
              </div>
            </div>
          </>
        ) : null}
      </form>
      <CreateCategory
        open={showCreate}
        handleClose={(needRefetch) => {
          setShowCreate(false);
          if (needRefetch) {
            refetchCategory();
            refetchSubCategory();
            refetchChildCategory();
          }
        }}
        categories={category?.data ?? []}
        subCategories={subCategory?.data ?? []}
      />
      <MetadataModal
        open={showMetadata}
        handleClose={(data) => {
          if (data) {
            setValue("metaTitle", data.title);
            setValue("metaDescription", data.description);
          }
          setShowMetadata(false);
        }}
        title={metaTitle}
        description={metaDescription}
      />
    </ContentContainer>
  );
};

export default ArticleForm;
