import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CategoryFormI,
  CategoryPayloadI,
} from "_interfaces/category.interfaces";
import { useCreateCategoryMutation } from "services/modules/crafter";
import { uploadFile } from "services/modules/file";
import { useAppSelector } from "store";
import { errorHandler } from "services/errorHandler";

const useCategoryForm = () => {
  const [createCategory, stateCreate] = useCreateCategoryMutation();
  const { accessToken } = useAppSelector((state) => state.auth);
  const schema = yup
    .object({
      name: yup.string().required(),
      description: yup.string().required(),
      image: yup.mixed().required(),
    })
    .required();
  const defaultValues: CategoryFormI = {
    name: "",
    description: "",
    image: undefined
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
    setValue,
  } = useForm<CategoryFormI>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const create = async (data: CategoryFormI) => {
    try {
      const fileUrl = await uploadFile(accessToken!, data.image![0]);
      const categoryId = data.categoryId
        ? +data.categoryId.value.split("-")[0]
        : undefined;
      const subCategoryId = data.subCategoryId
        ? +data.subCategoryId.value.split("-")[0]
        : undefined;
      createCategory({
        name: data.name,
        description: data.description,
        image: fileUrl,
        categoryId,
        subCategoryId,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCreate = handleSubmit(create);

  return {
    handleCreate,
    register,
    errors,
    watch,
    defaultValues,
    control,
    setValue,
    stateCreate,
  };
};

export default useCategoryForm;
