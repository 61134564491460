import { PagingDTO } from "_interfaces/pagination.interface";
import { GetTransactionResI } from "_interfaces/transaction.interfaces";
import { Api } from "services/api";

export const transactionApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<GetTransactionResI, PagingDTO>({
      query: (params) => ({
        url: "billing/admin/user-transactions",
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getUserTransactions: build.query<
      GetTransactionResI,
      { id: string; params: PagingDTO }
    >({
      query: ({ id, params }) => ({
        url: `billing/admin/user-transactions/${id}`,
        params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { useGetTransactionsQuery, useGetUserTransactionsQuery } =
  transactionApi;
