import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import Pagination from "components/table/pagination";
import { useGetCollaboratorQuery } from "services/modules/collabolator";
import { PagingDTO } from "_interfaces/pagination.interface";
import { CollaboratorI } from "_interfaces/collaborator.interfaces";
import CreateCollaborator from "./modal/create.modal";
import { Button } from "react-daisyui";

export const collaboratorRouteName = "";
const CollaboratorPage = () => {
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, refetch } = useGetCollaboratorQuery(params);
  const [show, setShow] = useState(false);

  const header: Columns<CollaboratorI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Display Name",
      // render: (data) => (
      //   <div
      //     onClick={() => {}}
      //     className="underline hover:text-blue-800 cursor-pointer"
      //   >
      //     {data.name}
      //   </div>
      // ),
    },
    {
      fieldId: "email",
      label: "Email",
    },
    {
      fieldId: "createdAt",
      label: "Registered At",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
  ];

  return (
    <>
      <ContentContainer>
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-2xl text-[#262626] font-semibold">
            Collaborator Management
          </h3>
          <div className="flex flex-row gap-2">
            <SearchInput
              placeholder="Search"
              onSubmit={({ text }) => {
                setParams((prev) => ({ ...prev, search: text }));
              }}
            />
            <Button
              onClick={() => setShow(true)}
              className="bg-san-juan text-white hover:bg-san-juan/90"
            >
              Create Collaborator
            </Button>
          </div>
        </div>
        <Table<CollaboratorI>
          data={data?.data}
          columns={header}
          loading={isLoading}
        />
        <Pagination
          currentPage={data?.meta.page ?? 0}
          totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
          onPageChange={(next) =>
            setParams((prev) => ({ ...prev, page: next }))
          }
        />
      </ContentContainer>
      <CreateCollaborator
        open={show}
        handleClose={(needRefetch) => {
          if (needRefetch) {
            refetch();
          }
          setShow(false);
        }}
      />
    </>
  );
};

export default CollaboratorPage;
