import { generateOperationImage } from "_helper/dump-image-generation";
import { DumpI } from "_interfaces/dump.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import ContentContainer from "components/container";
import DumpCard from "components/dump/card.component";
import DeletePopUp from "components/modal/other/Delete";
import SearchInput from "components/search-input";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import { useState } from "react";
import { Button } from "react-daisyui";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import {
  useDeleteDumpsMutation,
  useGetDumpsQuery,
} from "services/modules/dump";
import UpdateDump from "./section/update.modal";

export const dumpRouteName = "";
const DumpPage = () => {
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });
  const [deleteDump] = useDeleteDumpsMutation();
  const navigate = useNavigate();
  const { data, isLoading, refetch, isFetching } = useGetDumpsQuery(params);
  const [update, setUpdate] = useState<{ show: boolean, data?: DumpI }>({ show: false });

  const header: Columns<DumpI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "productUrl",
      label: "Product",
      render: (item) => {
        return (
          <img
            src={item.productUrl}
            className="w-20 h-20"
          />
        );
      },
    },
    {
      fieldId: "title",
      label: "Title",
    },
    {
      fieldId: "productCode",
      label: "Product Code",
      render: (item) => `#${item.productCode}`,
    },
    {
      fieldId: "creator",
      label: "Creator",
    },
    {
      fieldId: "operationType",
      label: "Operation",
      render: (item) => {
        return (
          <div className="bg-white flex items-center justify-center rounded-full p-2 gap-2.5 max-w-fit max-h-fit">
            {generateOperationImage(item.operationType).map((operation) => (
              <img
                src={operation}
                className="w-[15px] h-[15px]"
              />
            ))}
          </div>
        );
      },
    },
    {
      fieldId: "favoriteCount",
      label: "Favorites",
    },
    {
      fieldId: "downloads",
      label: "Downloads",
      render: (item) => {
        return (<div>{item.downloads.length}</div>)
      }
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<MdEdit />}
            onClick={() => {
              setUpdate({ show: true, data });
            }}
          >
            Edit
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdDelete />}
            onClick={() => handleDeletePopUp(data.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleDeletePopUp = (id?: string) => {
    setIsDeletePopupOpen((prev) => ({ open: !prev.open, id }));
  };

  const handleDeleteArticle = async (): Promise<void> => {
    try {
      await deleteDump(isDeletePopupOpen.id!).unwrap();
      handleDeletePopUp();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Brizy Product Management
        </h3>
        <div className="flex flex-row gap-2">
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setParams((prev: any) => ({ ...prev, search: text }));
            }}
          />
          <Button
            onClick={() => {
              navigate("create");
            }}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Upload Products
          </Button>
        </div>
      </div>
      <Table<DumpI>
        data={data?.data}
        columns={header}
        loading={isLoading || isFetching}
        currentPage={params.page}
        limit={params.limit}
      />
      <div className="mt-6">
        <Pagination
          currentPage={data?.meta.page ?? 0}
          totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
          onPageChange={(next) =>
            setParams((prev) => ({ ...prev, page: next }))
          }
        />
      </div>
      <UpdateDump
        open={update.show}
        handleClose={(needRefetch) => {
          setUpdate({ show: false });
          if (needRefetch) {
            refetch();
          }
        }}
        data={update.data}
      />
      <DeletePopUp
        isOpen={isDeletePopupOpen.open}
        data={"Data"}
        onClose={handleDeletePopUp}
        onEdit={handleDeleteArticle}
        menu="Data"
      />
    </ContentContainer>
  );
};

export default DumpPage;

