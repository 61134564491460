import { GetAffiliateResI } from "_interfaces/affiliate.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import { Api } from "services/api";

export const affiliateApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getAffiliateRequest: build.query<GetAffiliateResI, PagingDTO>({
      query: (params) => ({ url: "/affiliate/admin/request", params }),
      keepUnusedDataFor: 0,
    }),
    approve: build.mutation<void, string>({
      query: (id) => ({
        url: `/affiliate/admin/approve/${id}`,
        method: "POST",
      }),
    }),
    reject: build.mutation<void, string>({
      query: (id) => ({
        url: `/affiliate/admin/reject/${id}`,
        method: "POST",
        body: {
          reason: "Rejected by admin",
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAffiliateRequestQuery,
  useApproveMutation,
  useRejectMutation,
} = affiliateApi;
