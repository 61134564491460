import { CategoryI } from "_interfaces/category.interfaces";
import CInput from "components/input";
import Select from "components/select";
import ValidationError from "components/validation/error";
import useCategoryForm from "hooks/category/useCategoryForm";
import useFilePreview from "hooks/shared/useFilePreview";
import { useEffect } from "react";
import { Button, Modal } from "react-daisyui";
import { Controller } from "react-hook-form";
import { IoClose } from "react-icons/io5";

const CreateCategory = ({
  open,
  handleClose,
  categories,
  subCategories,
}: {
  open: boolean;
  handleClose: (refetch?: boolean) => void;
  categories: CategoryI[];
  subCategories: CategoryI[];
}) => {
  const { handleCreate, errors, register, setValue, stateCreate, watch } =
    useCategoryForm();
  const bgImage = watch("image");
  const [bgImagePreview] = useFilePreview(bgImage);
  const categoryOptions = categories.map((item) => ({
    label: item.name,
    value: `${item.id}-parent`,
  }));
  const subCategoryOptions = subCategories.map((item) => ({
    label: item.name,
    value: `${item.id}-sub`,
  }));

  useEffect(() => {
    if (stateCreate.isSuccess) {
      handleClose(true);
    }
  }, [stateCreate.isSuccess]);

  return (
    <Modal
      className="bg-white w-1/3 max-w-[900px]"
      open={open}
    >
      <Modal.Header className="flex flex-row justify-between">
        Create New Category
        <IoClose onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body className="overflow-scroll">
        <form
          className="p-2 flex flex-col gap-4"
          onSubmit={handleCreate}
        >
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Parent
            </label>
            <Select
              rounded={true}
              options={[...categoryOptions, ...subCategoryOptions]}
              onChange={(e) => {
                if (e.value.includes("parent")) {
                  setValue("categoryId", e);
                  setValue("subCategoryId", undefined);
                } else {
                  setValue("subCategoryId", e);
                  setValue("categoryId", undefined);
                }
              }}
            />
          </div>
          <div>
            <label
              htmlFor="merchantID"
              className="font-semibold text-[#262626]"
            >
              Category Name
            </label>
            <CInput
              {...register("name")}
              type="text"
            />
            <ValidationError error={errors.name} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Description
            </label>
            <CInput {...register("description")} />
            <ValidationError error={errors.description} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Image
            </label>
            {bgImagePreview && (
              <img
                className="flex mx-auto w-[100px] h-[100px] object-fill mb-2"
                src={bgImagePreview}
                alt=""
              />
            )}
            <CInput
              {...register("image")}
              type="file"
            />
            <ValidationError error={errors.image} />
          </div>
          <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
            <Button
              type="reset"
              className="border-seeds text-seeds"
              onClick={() => handleClose()}
              loading={stateCreate.isLoading}
              disabled={stateCreate.isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan/90"
              loading={stateCreate.isLoading}
              disabled={stateCreate.isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCategory;
