import { ManagementType } from "_interfaces/collaborator.interfaces";

interface SubMenuItem {
  name: string;
  path: string;
}
interface MenuItem {
  name: string;
  path: string;
  child?: SubMenuItem[];
  expand?: boolean;
  role: ManagementType[];
}

const menuItems: MenuItem[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    role: [ManagementType.Admin],
  },
  {
    name: "Collaborator Management",
    path: "/collaborator",
    role: [ManagementType.Admin],
  },
  {
    name: "User Management",
    path: "/user",
    role: [ManagementType.Admin],
  },
  {
    name: "Affiliate Management",
    path: "/affiliate",
    role: [ManagementType.Admin],
  },
  {
    name: "Category Management",
    path: "/category",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Product Management",
    path: "/product",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Article Management",
    path: "/article",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Transaction",
    path: "/transaction",
    role: [ManagementType.Admin],
  },
  {
    name: "Drizy Breezy",
    path: "/dump",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Drizy Breezy Theme",
    path: "/theme",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Drizy Breezy Creator",
    path: "/creator",
    role: [ManagementType.Admin],
  },
  {
    name: "Campaign Users",
    path: "/campaign-user",
    role: [ManagementType.Admin],
  },
  {
    name: "Campaign Messages",
    path: "/campaign-messages",
    role: [ManagementType.Admin],
  },
];

export { menuItems };
export type { MenuItem };
