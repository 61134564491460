import { PagingDTO } from "_interfaces/pagination.interface";
import { PurchaseI } from "_interfaces/post.interface";
import { TransactionI } from "_interfaces/transaction.interfaces";
import ContentContainer from "components/container";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import { PurchaseList } from "data/user";
import moment from "moment";
import { useState } from "react";
import { Badge, Link, Tooltip } from "react-daisyui";
import { toast } from "react-toastify";
import { useGetUserTransactionsQuery } from "services/modules/transaction";

const PurchaseHistorySection = ({ id }: { id: string }) => {
  const [paramTransaction, setParamTransaction] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetUserTransactionsQuery({
    id: id,
    params: paramTransaction,
  });
  const header: Columns<TransactionI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "status",
      label: "Status",
      render: (data) => (
        <Badge
          color={data.status ? "success" : "error"}
          className="text-white capitalize"
        >
          {data.status ? "success" : "failed"}
        </Badge>
      ),
    },
    {
      fieldId: "id",
      label: "Item",
      render: (data) => (
        <Tooltip
          color="neutral"
          message={data.product.name}
          className="text-wrap"
        >
          {data.product.name.substring(0, 20)}...
        </Tooltip>
      ),
    },
    {
      fieldId: "price",
      label: "Price",
    },
    {
      fieldId: "checkoutId",
      label: "Checkout Id",
      render: (data) => (
        <div
          onClick={() => {
            navigator.clipboard.writeText(data.checkoutId);
            toast("Checkout ID coppied!");
          }}
          className="cursor-pointer"
        >
          <Tooltip
            color="neutral"
            message={data.checkoutId}
            className="text-wrap"
          >
            {data.sessionId?.substring(0, 10)}...
          </Tooltip>
        </div>
      ),
    },
    {
      fieldId: "createdAt",
      label: "Date",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
  ];
  return (
    <ContentContainer>
      <h3 className="text-xl text-[#262626] font-semibold mb-4">
        Purchase History
      </h3>
      <div className="w-full overflow-auto">
        <Table<TransactionI>
          data={data?.data}
          loading={isLoading}
          columns={header}
        />
        <Pagination
          currentPage={paramTransaction.page}
          totalPages={Math.ceil(
            (data?.meta.total ?? 0) / paramTransaction.limit,
          )}
          onPageChange={(page) => {
            setParamTransaction((prev) => ({ ...prev, page }));
          }}
        />
      </div>
    </ContentContainer>
  );
};

export default PurchaseHistorySection;
