import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "store";
import { uploadFile } from "services/modules/file";
import {
  useCreateArticleMutation,
  useUpdateArticleMutation,
} from "services/modules/article";
import {
  CreateArticleForm,
  CreateArticlePayload,
} from "_interfaces/article.interfaces";

const useArticleForm = (id?: string) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createArticle] = useCreateArticleMutation();
  const [updateArticle] = useUpdateArticleMutation();
  const { accessToken } = useAppSelector((state) => state.auth);

  const schema = yup.object().shape({
    title: yup
      .string()
      .required("Title cannot empty")
      .max(60, "Title cannot more than 255 char")
      .min(5, "Title cannot less than 5 char"),
    description: yup
      .string()
      .required("Content cannot empty")
      .min(200, "Content cannot less than 200 char"),
    banner: yup.object().shape({
      file: yup
        .mixed()
        .required("Banner cannot empty, please select any image"),
    }),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    setValue,
    reset,
  } = useForm<CreateArticleForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      banner: { url: "" },
      categories: [],
      subCategories: [],
      childSubCategories: [],
    },
  });

  const create = async (data: CreateArticleForm) => {
    try {
      setIsLoading(true);
      let payload: CreateArticlePayload = {
        title: data.title,
        description: data.description,
        categories: data.categories,
        subCategories: data.subCategories,
        childSubCategories: data.childSubCategories,
        tags: data.tags.map((item) => item.text),
        banner: "",
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
      };
      if (data.banner.file && data.banner.file[0]) {
        const file = await uploadFile(
          accessToken!,
          data.banner.file[0] as File,
        );

        payload.banner = file;
      }
      await createArticle(payload).unwrap();
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: CreateArticleForm) => {
    try {
      setIsLoading(true);
      let payload: CreateArticlePayload = {
        title: data.title,
        description: data.description,
        categories: data.categories,
        subCategories: data.subCategories,
        childSubCategories: data.childSubCategories,
        tags: data.tags.map((item) => item.text),
        banner: data.banner.url ?? "",
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
      };
      if (data.banner.file && data.banner.file[0]) {
        const file = await uploadFile(
          accessToken!,
          data.banner.file[0] as File,
        );

        payload.banner = file;
      }
      await updateArticle({ id: id!, body: payload }).unwrap();
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = handleSubmit(create);
  const handleUpdate = handleSubmit(update);

  return {
    handleCreate,
    handleUpdate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    setValue,
    reset,
  };
};

export default useArticleForm;
