import { Dialog } from "@headlessui/react";
import { Puck, Render, Config, Data } from "@measured/puck";
import "@measured/puck/puck.css";
import ContentContainer from "components/container";
import CInput from "components/input";
import ValidationError from "components/validation/error";
import { useState } from "react";
import { Button } from "react-daisyui";

// Save the data to your database

// Render Puck editor
export function Campaign() {
  const [result, setResult] = useState<string>("{}");
  const [createView, setCreateView] = useState(false);
  const save = (data: Data) => {
    setResult(JSON.stringify(data));
    setCreateView(false);
  };
  const config: Config = {
    root: {
      render: ({ children }) => {
        return <div className="bg-white">{children}</div>;
      },
    },
    categories: {
      Typography: {
        components: ["Title", "Paragraph", "List"],
      },
      Actions: {
        components: ["Button"],
      },
      Assets: {
        components: ["Jumbotron"],
      },
    },
    components: {
      // PuckRichText,
      Title: {
        fields: {
          title: {
            label: "Title",
            type: "text",
          },
          fontSize: {
            label: "Font Size",
            type: "number",
          },
          textAlign: {
            label: "Text Align",
            type: "select",
            options: [
              { label: "Left", value: "start" },
              { label: "Center", value: "center" },
              { label: "Right", value: "end" },
            ],
          },
          fontWeight: {
            label: "Font Weight",
            type: "select",
            options: [
              { label: "300", value: 300 },
              { label: "400", value: 400 },
              { label: "500", value: 500 },
              { label: "600", value: 600 },
              { label: "700", value: 700 },
              { label: "800", value: 800 },
            ],
          },
        },
        defaultProps: {
          title: "Title here",
          fontSize: 18,
          textAlign: "start",
          fontWeight: 300,
        },
        render: ({ title, fontSize, textAlign, fontWeight }) => {
          return (
            <h1
              style={{
                fontSize,
                textAlign,
                fontWeight,
                width: "100%",
                backgroundColor: "white",
                padding: 24,
              }}
            >
              {title}
            </h1>
          );
        },
      },
      Jumbotron: {
        fields: {
          source: {
            label: "Image Url",
            type: "text",
          },
          alt: {
            label: "Image Alt",
            type: "text",
          },
          height: {
            label: "Height",
            type: "number",
          },
        },
        defaultProps: {
          height: 200,
          alt: "Alt Jumbotron",
        },
        render: ({ source, height, alt }) => {
          return (
            <div>
              <img
                alt={alt}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  height: `${height}px`,
                }}
                src={source}
              />
            </div>
          );
        },
      },
      VerticalSpace: {
        fields: {
          height: {
            type: "number",
          },
        },
        defaultProps: {
          height: 10,
        },
        render: ({ height }) => {
          return <div style={{ height, width: "100%" }} />;
        },
      },
      Paragraph: {
        fields: {
          text: { type: "textarea", label: "Text" },
          letterSpacing: {
            type: "select",
            label: "Letter Spacing",
            options: [
              { label: "0.5px", value: 0.5 },
              { label: "0.75px", value: 0.75 },
              { label: "1px", value: 1 },
              { label: "1.25px", value: 1.25 },
              { label: "1.5px", value: 1.5 },
            ],
          },
          textAlign: {
            label: "Text Align",
            type: "select",
            options: [
              { label: "Left", value: "start" },
              { label: "Center", value: "center" },
              { label: "Justify", value: "justify" },
              { label: "Right", value: "end" },
            ],
          },
        },
        defaultProps: {
          text: "Paragraph",
          letterSpacing: 0.5,
          textAlign: "start",
        },
        render: ({ text, letterSpacing, textAlign }) => (
          <div style={{ padding: 24 }}>
            <p style={{ letterSpacing: parseFloat(letterSpacing), textAlign }}>
              {text}
            </p>
          </div>
        ),
      },
      Button: {
        fields: {
          url: { type: "text", label: "URL" },
          title: { type: "text", label: "Title" },
          backgroundColor: {
            type: "custom",
            label: "Color",
            render: ({ name, onChange, value }) => {
              return (
                <input
                  type="color"
                  name={name}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                />
              );
            },
          },
          fontColor: {
            type: "custom",
            label: "Font Color",
            render: ({ name, onChange, value }) => {
              return (
                <input
                  type="color"
                  name={name}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                />
              );
            },
          },
          buttonType: {
            type: "radio",
            label: "Button Type",
            options: [
              { label: "filled", value: "filled" },
              { label: "outline", value: "outline" },
            ],
          },
          position: {
            type: "radio",
            label: "Position",
            options: [
              { label: "start", value: "start" },
              { label: "center", value: "center" },
              { label: "end", value: "end" },
            ],
          },
          borderRadius: {
            type: "number",
            label: "Border Radius",
          },
        },
        defaultProps: {
          url: "Target URL",
          title: "Button title",
          backgroundColor: "",
          fontColor: "",
          buttonType: "filled",
          position: "start",
          borderRadius: 10,
        },
        render: ({
          url,
          title,
          backgroundColor,
          fontColor,
          position,
          borderRadius,
          buttonType,
        }) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: position,
                padding: "0px 24px",
                width: "100%",
              }}
            >
              <a
                href={url}
                target="__blank"
                style={{
                  cursor: "pointer",
                  padding: "10px 20px",
                  backgroundColor:
                    buttonType === "filled" ? backgroundColor : "transparent",
                  borderColor:
                    buttonType === "filled" ? "none" : backgroundColor,
                  borderWidth: buttonType === "filled" ? 0 : 1,
                  color: fontColor,
                  borderRadius,
                  fontWeight: 600,
                }}
              >
                {title}
              </a>
            </div>
          );
        },
      },
      List: {
        fields: {
          items: {
            type: "array",
            arrayFields: {
              title: { type: "text" },
            },
          },
          listStyle: {
            label: "List Style",
            type: "select",
            options: [
              { label: "Upper Roman", value: "upper-roman" },
              { label: "Lower Alpha", value: "lower-alpha" },
              { label: "Circle", value: "circle" },
              { label: "Square", value: "square" },
              { label: "Number", value: "number" },
            ],
          },
        },
        defaultProps: {
          items: [],
          listStyle: "upper-roman",
        },
        render: ({ items, listStyle }) => {
          return listStyle === "number" ? (
            <ol style={{ padding: "0px 24px", listStyleType: listStyle }}>
              {items.map((item: any, i: number) => (
                <li key={i}>{item.title}</li>
              ))}
            </ol>
          ) : (
            <ul style={{ padding: "0px 24px", listStyleType: listStyle }}>
              {items.map((item: any, i: number) => (
                <li key={i}>{item.title}</li>
              ))}
            </ul>
          );
        },
      },
    },
  };

  return (
    <ContentContainer>
      <form
        className="p-2 flex flex-col gap-4"
        // onSubmit={handleCreate}
      >
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="font-semibold text-[#262626]">
              Campaign Name
            </label>
            <CInput
              // {...register("name")}
              type="text"
            />
            {/* <ValidationError error={errors.name} /> */}
          </div>
          <div>
            <label className="font-semibold text-[#262626]">Campaign URL</label>
            <CInput
              // {...register("name")}
              type="text"
            />
            {/* <ValidationError error={errors.name} /> */}
          </div>
          <div>
            <label className="font-semibold text-[#262626]">Publish Date</label>
            <CInput
              // {...register("name")}
              type="datetime-local"
            />
            {/* <ValidationError error={errors.name} /> */}
          </div>
          <div>
            <label className="font-semibold text-[#262626]">Expiry Date</label>
            <CInput
              // {...register("name")}
              type="datetime-local"
            />
            {/* <ValidationError error={errors.name} /> */}
          </div>
        </div>
        <div>
          <Button
            type="button"
            className="bg-san-juan text-white hover:bg-san-juan/90"
            onClick={() => setCreateView(true)}
            // loading={stateCreate.isLoading}
            // disabled={stateCreate.isLoading}
          >
            Set Page
          </Button>
        </div>
        {result !== "{}" && (
          <Render
            config={config}
            data={JSON.parse(result)}
          />
        )}
        <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
          <Button
            type="reset"
            className="border-seeds text-seeds"
            // onClick={() => handleClose()}
            // loading={stateCreate.isLoading}
            // disabled={stateCreate.isLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="bg-san-juan text-white hover:bg-san-juan/90"
            // loading={stateCreate.isLoading}
            // disabled={stateCreate.isLoading}
          >
            Save
          </Button>
        </div>
      </form>
      <Dialog
        as="div"
        className="relative z-[9999]"
        open={createView}
        onClose={() => {
          setCreateView(false);
        }}
      >
        <Puck
          config={config}
          data={JSON.parse(result)}
          onPublish={save}
        />
      </Dialog>
    </ContentContainer>
  );
}

